/** ***************************************************************************
 * Molecules / Navigation
 *************************************************************************** */

import ContactsModalComponent from '../modal/contacts/contacts';
import CaptureModalComponent from '../modal/capture/capture';

class NavigationComponent {
  constructor(element) {
    const body = document.querySelector('body');

    this.element = element;
    this.element.smNavigation = this;

    const contactsDialogFragment = this.buildContactsDialog();
    body.appendChild(contactsDialogFragment);
    this.contactsDialog = new ContactsModalComponent(body.lastElementChild);

    this.contactsButton = this.element.querySelector('.sm-c-button-black.sm-m-contacts');
    this.contactsButton.onclick = (event) => {
      event.preventDefault();
      this.contactsDialog.open();
    };

    const captureDialogFragment = this.buildCaptureDialog();
    body.appendChild(captureDialogFragment);
    this.captureDialog = new CaptureModalComponent(
      body.lastElementChild,
      document.querySelector('.sm-c-canvas')
    );

    this.captureButton = this.element.querySelector('.sm-c-button-black.sm-m-capture');
    this.captureButton.onclick = (event) => {
      event.preventDefault();
      this.captureDialog.open();
    };
  }

  destroy() {
    this.element.smNavigation = undefined;
    this.contactsButton.onclick = undefined;
    this.contactsDialog.element.remove();
    this.contactsDialog.destroy();
  }

  buildContactsDialog() {
    const modalTemplate = document.getElementById('sm-c-modal-contacts-template');
    return modalTemplate.content.cloneNode(true);
  }

  buildCaptureDialog() {
    const modalTemplate = document.getElementById('sm-c-modal-capture-template');
    return modalTemplate.content.cloneNode(true);
  }
}

window.addEventListener('supermatita:init', () => {
  const selector = '.sm-c-nav';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      const component = new NavigationComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.NavigationComponent) {
        dialog.NavigationComponent.destroy();
      }
    });
  }

  window.SUPERMATITA.register(selector, initialize, destroy);

  initialize();
});
