/** ***************************************************************************
 * Molecules / Modal / Base
 *************************************************************************** */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import dialogPolyfill from 'dialog-polyfill';

class ModalComponent {
  constructor(element) {
    this.element = element;
    element.smModal = this;
    dialogPolyfill.registerDialog(this.element);

    this.setUpEventListeners();

    this.closeButton = element.querySelector('.sm-c-modal-button.sm-m-close');
    this.closeButton.onclick = (event) => this.close();

    this.element.addEventListener('close', this.onclose);
  }

  destroy() {
    this.closeButton.onclick = undefined;
    this.element.removeEventListener('close', this.onclose);
  }

  // Methods

  open() {
    if (this.element.open) {
      // prevent HTMLDialogElement.InvalidStateError
      return;
    }
    this.element.showModal();
    disableBodyScroll(this.element, {
      // see https://github.com/willmcpo/body-scroll-lock#reservescrollbargap
      reserveScrollBarGap: true,
    });
    this.closeButton.blur();  // chrome and firefox focus the first button
    document.documentElement.classList.add('sm-m-pause')
  }

  close() {
    this.element.addEventListener('transitionend', this.ontransitionend);
    this.element.classList.add('sm-m-closing');
  }

  // event listeners

  setUpEventListeners() {
    this.onclose = (event) => {
      enableBodyScroll(this.element);
      document.documentElement.classList.remove('sm-m-pause')
    };

    this.ontransitionend = (event) => {
      if (event.pseudoElement == '::before') { // background
        this.element.classList.remove('sm-m-closing');
        this.element.close();
        this.element.removeEventListener('transitionend', this.ontransitionend);
      }
    };
  }
}

export { ModalComponent as default };
