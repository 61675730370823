/** ***************************************************************************
 * Organisms / CMS plugins / Secret word
 *************************************************************************** */

import SecretModalComponent from '../../../02-molecules/cms_plugins/secret_modal/secret_modal';

window.addEventListener('supermatita:init', () => {
  const className = 'sm-c-secret';

  const modalTemplate = document.getElementById('sm-c-secret-modal-template');

  class SecretWordComponent {
    constructor(element) {
      this.element = element;
      this.title = element.getAttribute('title');
      this.definition = element.getAttribute('aria-description');

      const dialogFragment = this.buildDialog();
      const body = document.querySelector('body');
      body.appendChild(dialogFragment);
      this.dialog = new SecretModalComponent(body.lastElementChild);
      this.dialog.smSecretWord = this;

      this.content = element.textContent.trim();

      element.smSecretWord = this;
      element.classList.add('sm-m-js');

      this.splitLetters();
      this.button = this.addButton();
    }

    destroy() {
      this.dialog.destroy();
      this.element.classList.remove('sm-m-js');
      this.element.innerHTML = '';
      this.element.textContent = this.content;
      this.element.smSecretWord = undefined;
    }

    /**
     * Ensure the secret word is visible and in focus.
     */
    scrollIntoView() {
      this.element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
        end: 'center',
      });
    }

    focus() {
      this.button.focus();
    }

    blur() {
      this.button.blur();
    }

    /**
     * Reveal the secret word
     */
    reveal() {
      this.scrollIntoView();
      this.element.classList.add('sm-m-reveal');
    }

    /**
     * Unreveal the secret word
     */
    hide() {
      this.element.classList.remove('sm-m-reveal');
    }

    /**
     * Build the modal dialog.
     */
    buildDialog() {
      const dialog = modalTemplate.content.cloneNode(true);
      dialog.querySelector('h1').textContent = this.title;
      dialog.querySelector('div > p').textContent = this.definition;
      return dialog;
    }

    /**
     * Split content in single letter elements.
     */
    splitLetters() {
      const contentNode = document.createElement('span');
      contentNode.textContent = this.content;
      this.element.textContent = '';
      [...this.content].forEach((letter, index) => {
        const letterNode = document.createElement('u');
        letterNode.setAttribute('title', letter);
        letterNode.setAttribute('aria-hidden', true);
        letterNode.style.setProperty('--sm-c-secret__letter--index', index);
        this.element.appendChild(letterNode);
      });
      this.element.appendChild(contentNode);
    }

    /**
     * Add the modal open button.
     */
    addButton() {
      const button = document.createElement('button');

      button.textContent = '?';
      this.element.appendChild(button);
      button.onclick = () => this.dialog.open();
      return button;
    }

  }

  function initialize() {
    document.querySelectorAll(`.${className}`).forEach((secret) => {
      new SecretWordComponent(secret);
    });
  }

  function destroy() {
    document.querySelectorAll(`.${className}`).forEach((secret) => {
      if (secret.smSecretWord) {
        secret.smSecretWord.destroy();
      }
    });
  }

  window.SUPERMATITA.register(className, initialize, destroy);

  initialize();
});
