/** ***************************************************************************
 * Molecules / Modal / Capture
 *************************************************************************** */

import ModalComponent from '../00-base/base';

class CaptureModalComponent extends ModalComponent {
  constructor(element, canvas) {
    super(element);

    this.element.addEventListener('close', this.onclose);

    this.canvas = canvas;
    this.submitUrl = this.element.getAttribute('data-submit');

    this.button = this.element.querySelector('.sm-c-button-thankful');
    this.button.onclick = (event) => {
      this.submitScreenshot();
      this.button.setAttribute('disabled', '');
    };
  }

  destroy() {
    super.destroy();
    this.element.removeEventListener('close', this.onclose);
    this.button.onclick = undefined;
  }

  // methods

  submitScreenshot() {
    if (this.submitUrl) {
      const data = new FormData();

      // handle csrf
      this.element.querySelectorAll('input[type="hidden"]').forEach((field) => {
        data.append(field.name, field.value);
      })

      data.append('viewport_width', window.innerWidth);
      data.append('viewport_height', window.innerHeight);
      data.append('path', window.location.pathname);

      if (this.canvas) {
        this.canvas.toBlob((blob) => {
          data.append('picture', blob, 'picture.png');
          fetch(this.submitUrl, {
            'method': 'POST',
            body: data,
          }).then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error();
          }).then((result) => {
            console.log(result);
          }).catch((error) => {
            console.log(error);
          });
        }, 'image/png');
      }
    }
  }

  // event listeners

  setUpEventListeners() {
    super.setUpEventListeners();  // this sets this.onclose
    const oldOnclose = this.onclose;
    this.onclose = (event) => {
      oldOnclose(event);
      this.button.removeAttribute('disabled');
    };
  }
}

export { CaptureModalComponent as default };
