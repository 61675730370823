/** ***************************************************************************
 * Molecules / Canvas remote
 *************************************************************************** */

class RemoteComponent {
  constructor(element, canvasComponent) {
    this.element = element;
    this.element.smRemote = this;
    this.element.classList.add('sm-m-js');

    this.setUpEventListeners();

    this.canvasComponent = canvasComponent;

    this.draw = this.element.querySelector('.sm-c-remote-button:nth-child(2)');
    this.drag = this.element.querySelector('.sm-c-remote-button:nth-child(1)');
    this.erase = this.element.querySelector('.sm-c-remote-button:nth-child(3)');

    this.draw.querySelector(':scope > input').onchange = (event) => {
      if (event.target.checked) {
        this.canvasComponent.activateTool('pencil');
      }
    };
    this.erase.querySelector(':scope > input').onchange = (event) => {
      if (event.target.checked) {
        this.canvasComponent.activateTool('eraser');
      }
    };
    this.drag.querySelector(':scope > input').onchange = (event) => {
      if (event.target.checked) {
        this.canvasComponent.deactivate();
      }
    };

    if (matchMedia('(pointer:coarse)').matches) {
      this.drag.click();
    } else {
      this.draw.click();
    }

    document.addEventListener('supermatita:secret:answer', this.onanswer);
  }

  destroy() {
    this.draw.querySelector(':scope > input').onchange = undefined;
    this.erase.querySelector(':scope > input').onchange = undefined;
    this.drag.querySelector(':scope > input').onchange = undefined;
    document.removeEventListener('supermatita:secret:answer', this.onanswer);
    this.element.classList.remove('sm-m-js');
  }

  // Event listeners

  setUpEventListeners() {
    this.onanswer = (event) => {
      this.draw.click();
    };
  }
}


export { RemoteComponent as default };
