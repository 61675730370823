/** ***************************************************************************
 * Molecules / CMS plugins / Secret word modal
 *************************************************************************** */

import ModalComponent from '../../modal/00-base/base'

class SecretModalComponent extends ModalComponent {
  constructor(element) {
    super(element);
    const buttons = element.querySelectorAll('.sm-c-modal-button');

    this.answerButton = buttons[1];
    this.answerButton.onclick = () => this.answer();

    this.peekButton = buttons[2];
    this.peekButton.onclick = () => this.peek();
  }

  destroy() {
    this.answerButton.onclick = undefined;
    this.peekButton.onclick = undefined;
    super.destroy();
  }

  // Methods

  answer() {
    if (this.smSecretWord) {
      this.smSecretWord.hide();
      this.element.addEventListener(
        'close',
        () => {
          const lastLetter = this.smSecretWord.element.querySelector('u:last-of-type');

          this.smSecretWord.hide();
          this.smSecretWord.scrollIntoView();
          this.smSecretWord.focus();  // this starts the secret letter animation

          // pause modal buttons
          document.documentElement.classList.add('sm-m-pause');

          // resume modal buttons after the secret letter animation
          lastLetter.addEventListener('animationend', (event) => {
            setTimeout(() => {
              document.documentElement.classList.remove('sm-m-pause');
            }, 1000);
          }, { once: true });

        },
        { once: true },
      );
    }
    this.close();
    document.dispatchEvent(new Event('supermatita:secret:answer'));
  }

  peek() {
    if (this.smSecretWord) {
      this.element.addEventListener(
        'close',
        () => {
          this.smSecretWord.blur();
          this.smSecretWord.reveal();
        },
        { once: true },
      );
    }
    this.close();
  }
}

window.addEventListener('supermatita:init', () => {
  const selector = '.sm-c-modal.sm-m-secret';

  function initialize() {
    document.querySelectorAll(selector).forEach((dialog) => {
      const component = new SecretModalComponent(dialog);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((dialog) => {
      if (dialog.smSecretModal) {
        dialog.smSecretModal.destroy();
      }
    });
  }

  window.SUPERMATITA.register(selector, initialize, destroy);

  initialize();
});

export { SecretModalComponent as default };
